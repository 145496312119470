var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"col-12 col-sm-12 col-md-3"},[_c('v-text-field',{attrs:{"hide-details":"","label":_vm.$t('points.number')},model:{value:(_vm.number_filter),callback:function ($$v) {_vm.number_filter=$$v},expression:"number_filter"}})],1),_c('v-col',{staticClass:"col-12 col-sm-12 col-md-3"},[_c('v-select',{attrs:{"hide-details":"","items":_vm.city_items,"label":_vm.$t('points.city'),"clearable":""},model:{value:(_vm.city_filter),callback:function ($$v) {_vm.city_filter=$$v},expression:"city_filter"}})],1),_c('v-col',{staticClass:"col-12 col-sm-12 col-md-3"},[_c('v-select',{attrs:{"hide-details":"","items":_vm.vendor_items,"label":_vm.$t('points.vendor'),"clearable":""},model:{value:(_vm.vendor_filter),callback:function ($$v) {_vm.vendor_filter=$$v},expression:"vendor_filter"}})],1),_c('v-col',{staticClass:"col-12 col-sm-12 col-md-3"},[_c('v-select',{attrs:{"hide-details":"","items":_vm.fw_items,"label":_vm.$t('points.firmware'),"clearable":""},model:{value:(_vm.fw_filter),callback:function ($$v) {_vm.fw_filter=$$v},expression:"fw_filter"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.charge_points},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
                  name: 'point_info',
                  params: { point_id: item.point_number },
                }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Информация")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }