<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 col-sm-12 col-md-3"
        ><v-text-field
          hide-details
          :label="$t('points.number')"
          v-model="number_filter"
        ></v-text-field
      ></v-col>
      <v-col class="col-12 col-sm-12 col-md-3"
        ><v-select
          hide-details
          v-model="city_filter"
          :items="city_items"
          :label="$t('points.city')"
          clearable
        ></v-select
      ></v-col>
      <v-col class="col-12 col-sm-12 col-md-3"
        ><v-select
          hide-details
          v-model="vendor_filter"
          :items="vendor_items"
          :label="$t('points.vendor')"
          clearable
        ></v-select
      ></v-col>
      <v-col class="col-12 col-sm-12 col-md-3"
        ><v-select
          hide-details
          v-model="fw_filter"
          :items="fw_items"
          :label="$t('points.firmware')"
          clearable
        ></v-select
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-data-table :headers="headers" :items="charge_points">
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :to="{
                    name: 'point_info',
                    params: { point_id: item.point_number },
                  }"
                  ><v-icon>mdi-open-in-new</v-icon></v-btn
                >
              </template>
              <span>Информация</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Points",
  data: () => {
    return {
      number_filter: null,
      city_filter: null,
      vendor_filter: null,
      fw_filter: null,
      options: {
        sortBy: ["point_number"],
        sortDesc: [false],
      },
    };
  },
  created() {
    this.$store.dispatch("get_points");
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("headers.number"),
          align: "start",
          sortable: true,
          value: "point_number",
        },
        {
          text: this.$t("headers.location"),
          align: "start",
          sortable: true,
          value: this.$store.getters.LOCALE == "ru" ? "address" : "address_en",
        },
        {
          text: this.$t("headers.vendor"),
          align: "start",
          sortable: true,
          value: "vendor",
        },
        {
          text: this.$t("headers.serial_number"),
          align: "start",
          sortable: true,
          value: "serial",
        },
        {
          text: this.$t("headers.transferred"),
          align: "start",
          sortable: true,
          value: "energy_total",
        },
        { text: "", value: "actions", sortable: false },
      ]
    },
    charge_points() {
      return this.$store.getters.POINTS;
    },
    points_loading() {
      return this.$store.getters.LOADING_POINTS;
    },
    total_points() {
      return this.$store.getters.TOTAL_POINTS;
    },
    city_items() {
      return this.$store.getters.CITY_FILTER;
    },
    vendor_items() {
      return this.$store.getters.VENDOR_FILTER;
    },
    fw_items() {
      return this.$store.getters.FW_FILTER;
    },
  },
};
</script>

<style scoped></style>
